import {
  createRouter,
  createWebHistory
} from 'vue-router'
// import Home from '../views/Home.vue'
import feedBack from './feedBack.js'
const routes = [{
    path: '/',
    redirect: '/examine',
    // name: 'Home',
    // component: Home
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/personalCenter.vue')
  },
  {
    path: '/examine',
    name: 'examine',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/examine.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/notice.vue')
  },
  {
    path: '/fee',
    name: 'fee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/fee.vue')
  },
  {
    path: '/title',
    name: 'title',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/title.vue')
  },
  {
    path: '/editInformation',
    name: 'editInformation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/editInformation.vue')
  },
  {
    path: '/sessionsNum',
    name: 'sessionsNum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/sessionsNum.vue')
  },
  {
    path: '/invitation',
    name: 'invitation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/invitation.vue')
  },
  {
    path: '/membershipFee',
    name: 'membershipFee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/membershipFee.vue')
  },
  {
    path: '/Information',
    name: 'Information',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Information.vue')
  },
  {
    path: '/addMember',
    name: 'addMember',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/addMember.vue')
  },
  //member
  {
    path: '/member',
    name: 'member',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/member.vue')
  },
  {
    path: '/banner',
    name: 'banner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/banner.vue')
  },
  {
    path: '/addBanner',
    name: 'addBanner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/addBanner.vue')
  },
  {
    path: '/icon',
    name: 'icon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/icon.vue')
  },
  {
    path: '/addIcon',
    name: 'addIcon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route leaderShip
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/addIcon.vue')
  },
  {
    path: '/leaderShip',
    name: 'leaderShip',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route leaderShip
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/leaderShip.vue')
  },
  {
    path: '/AppletInfo',
    name: 'AppletInfo',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route AppletInfo
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/AppletInfo.vue')
  },
  {
    path: '/integral',
    name: 'integral',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route integral
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/integral.vue')
  },
  {
    path: '/Registration',
    name: 'Registration',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Registration.vue')
  },
  {
    path: '/addActivity',
    name: 'addActivity',
    meta: {
      keepAlive: true
    },
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/addActivity.vue')
  },
  {
    path: '/addSessions',
    name: 'addSessions',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/addSessions.vue')
  },
  {
    path: '/grade',
    name: 'grade',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/grade.vue')
  },
  {
    path: '/IndexSession',
    name: 'IndexSession',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration editSession
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/IndexSession.vue')
  },
  {
    path: '/editSession',
    name: 'editSession',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration editSession
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/editSession.vue')
  },
  {
    path: '/maintainData',
    name: 'maintainData',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration maintainData
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/maintainData.vue')
  },
  {
    path: '/addIndex',
    name: 'addIndex',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration maintainData
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/addIndex.vue')
  },
  {
    path: '/Inputindex',
    name: 'Inputindex',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration maintainData
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Inputindex.vue')
  },
  {
    path: '/Scoringcriteria',
    name: 'Scoringcriteria',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration maintainData  lookGrade
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Scoringcriteria.vue')
  },
  {
    path: '/lookGrade',
    name: 'lookGrade',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration maintainData  lookGrade PriceSetting
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/lookGrade.vue')
  },
  {
    path: '/PriceSetting',
    name: 'PriceSetting',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration maintainData  PriceSetting PriceSetting
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/PriceSetting.vue')
  },
  {
    path: '/SupplyAudit',
    name: 'SupplyAudit',
    // route level code-splittingAppletInfo questionnaire
    // this generates a separate chunk (about.[hash].js)  for this route Registration OpportunityAudit  
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/SupplyAudit.vue')
  },
  {
    path: '/OpportunityAudit',
    name: 'OpportunityAudit',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration OpportunityAudit  
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/OpportunityAudit.vue')
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration questionnaire  addQuestionnaire 
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/questionnaire.vue')
  },
  {
    path: '/addQuestionnaire',
    name: 'addQuestionnaire',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration addQuestionnaire  questionDetail 
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/addQuestionnaire.vue')
  },
  {
    path: '/questionDetail',
    name: 'questionDetail',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration questionDetail  answerDetail 
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/questionDetail.vue')
  },
  {
    path: '/answerDetail',
    name: 'answerDetail',
    // route level code-splittingAppletInfo
    // this generates a separate chunk (about.[hash].js) for this route Registration answerDetail  answerDetail 
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/answerDetail.vue')
  },
  {
    path: '/service',
    name: 'service',
    // route level code-splittingAppletInfo
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/service.vue')
  },
  {
    path: '/addService',
    name: 'addService',
    // route level code-splittingAppletInfo
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/addService.vue')
  },
  {
    path: '/editService',
    name: 'editService',
    meta: {
      keepAlive: true
    },
    // route level code-splittingAppletInfo  ScoringMethod
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/editService.vue')
  },
  {
    path: '/ScoringMethod',
    name: 'ScoringMethod',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  ScoringMethod
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/ScoringMethod.vue')
  },
  {
    path: '/DataAnalysis',
    name: 'DataAnalysis',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  DataAnalysis jobManagement
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/DataAnalysis.vue')
  },
  {
    path: '/jobManagement',
    name: 'jobManagement',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  jobManagement jobManagement
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/jobManagement.vue')
  },
  {
    path: '/tabberSetting',
    name: 'tabberSetting',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  tabberSetting tabberSetting
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/tabberSetting.vue')
  },
  // {
  //   path: '/grouping',
  //   name: 'grouping',
  //   meta: {
  //     keepAlive: false
  //   },
  //   // route level code-splittingAppletInfo  grouping grouping
  //   // which is lazy-loaded when the route is visited.addService
  //   component: () => import( /* webpackChunkName: "about" */ '../views/grouping.vue')
  // },
  {
    path: '/groupMembers',
    name: 'groupMembers',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  groupMembers groupMembers
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/groupMembers.vue')
  },
  {
    path: '/system',
    name: 'system',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  system system
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/system.vue')
  },
  {
    path: '/Department',
    name: 'Department',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  Department Department
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/Department.vue')
  },
  {
    path: '/role',
    name: 'role',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/role.vue')
  },
  {
    path: '/roleDetail',
    name: 'roleDetail',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/roleDetail.vue')
  },
  {
    path: '/addRoleMember',
    name: 'addRoleMember',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/addRoleMember.vue')
  },
  {
    path: '/memberPermissions',
    name: 'memberPermissions',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/memberPermissions.vue')
  },
  {
    path: '/CollectionInformation',
    name: 'CollectionInformation',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/CollectionInformation.vue')
  },
  {
    path: '/Classification',
    name: 'Classification',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/Classification.vue')
  },
  {
    path: '/InformationAudit',
    name: 'InformationAudit',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/InformationAudit.vue')
  },
  {
    path: '/ExhibitManagement',
    name: 'ExhibitManagement',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/ExhibitManagement.vue')
  },
  {
    path: '/addExhibits',
    name: 'addExhibits',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/addExhibits.vue')
  },
  {
    path: '/ExhibitAudit',
    name: 'ExhibitAudit',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/ExhibitAudit.vue')
  },
  {
    path: '/PrivacyRights',
    name: 'PrivacyRights',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role NavigationSettings
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/PrivacyRights.vue')
  },
  {
    path: '/NavigationSettings',
    name: 'NavigationSettings',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role NavigationSettings
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/NavigationSettings.vue')
  }, {
    path: '/ExhibitClass',
    name: 'ExhibitClass',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addService
    component: () => import( /* webpackChunkName: "about" */ '../views/ExhibitClass.vue')
  },
  {
    path: '/branch',
    name: 'branch',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting
    component: () => import( /* webpackChunkName: "about" */ '../views/branch.vue')
  },
  {
    path: '/invitationSetting',
    name: 'invitationSetting',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role MessagePush
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting
    component: () => import( /* webpackChunkName: "about" */ '../views/invitationSetting.vue')
  },
  {
    path: '/MallClassification',
    name: 'MallClassification',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role role
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting
    component: () => import( /* webpackChunkName: "about" */ '../views/MallClassification.vue')
  },
  {
    path: '/addProducts',
    name: 'addProducts',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role roleOrderManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting
    component: () => import( /* webpackChunkName: "about" */ '../views/addProducts.vue')
  },
  {
    path: '/OrderManagement',
    name: 'OrderManagement',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role roleOrderManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting
    component: () => import( /* webpackChunkName: "about" */ '../views/OrderManagement.vue')
  },
  {
    path: '/CommodityManagement',
    name: 'CommodityManagement',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role roleOrderManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting
    component: () => import( /* webpackChunkName: "about" */ '../views/CommodityManagement.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role StoreManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting
    component: () => import( /* webpackChunkName: "about" */ '../views/orderDetail.vue')
  },
  {
    path: '/StoreInfo',
    name: 'StoreManagement',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role StoreManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting MessagePush
    component: () => import( /* webpackChunkName: "about" */ '../views/StoreManagement.vue')
  },
  {
    path: '/MessagePush',
    name: 'MessagePush',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role StoreManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting MessagePush
    component: () => import( /* webpackChunkName: "about" */ '../views/MessagePush.vue')
  },
  {
    path: '/workBench',
    name: 'workBench',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role StoreManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting workBench
    component: () => import( /* webpackChunkName: "about" */ '../views/workBench.vue')
  },
  {
    path: '/opportunityClassification',
    name: 'opportunityClassification',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role StoreManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting opportunityClassification
    component: () => import( /* webpackChunkName: "about" */ '../views/opportunityClassification.vue')
  },
  {
    path: '/ActivityData',
    name: 'ActivityData',
    meta: {
      keepAlive: false
    },
    // route level code-splittingAppletInfo  role StoreManagement
    // which is lazy-loaded when the route is visited.addServiceinvitationSetting ActivityData
    component: () => import( /* webpackChunkName: "about" */ '../views/ActivityData.vue')
  },
  ...feedBack,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router