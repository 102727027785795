import axios from 'axios'
import {
    ElMessage,
    ElMessageBox
} from 'element-plus'
import router from '@/router/index'
import {
    localGet,
    localRemove
} from './index'
import config from "@/utils/config";
const service = axios.create({
    baseURL: config.host_url,
    // baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000,
    // transformRequest: [
    //     (data) => {
    //         return data instanceof FormData ? data : Qs.stringify({ //此处的data类型判断为重要点，
    //             ...data
    //         });
    //     }
    // ]
})
service.defaults.retry = 4;
service.defaults.retryDelay = 1000;
// request 拦截器
service.interceptors.request.use(
    config => {
        config.headers['access-token'] = localGet('token') || ''
        config.headers['access-appId'] = localGet('AppId') || ''
        return config
    },
    error => {
        Promise.reject(error).catch(err=>{console.log(err)})
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const res = response.data

        if (res.Code == "400") {
            router.push({
                path: '/login'
            })
            localRemove('token')
            return Promise.reject('error')
        }else if (res.Code == '301') {
            
            // if(messageBox){
            //     ElMessageBox.closeAll()
            // }else{
            //     messageBox =     ElMessageBox.confirm('您的使用权限已经更改，请重新登录', '提示', {
            //         confirmButtonText: '确定返回登录',
            //         cancelButtonText: '取消',
            //         type: 'warning',
    
            //     }).then(() => {
            //         router.push({
            //             path: '/login'
            //         })
            //     })
            
            // }

            ElMessageBox.close()
            ElMessageBox.confirm('您的使用权限已经更改，请重新登录', '提示', {
                        confirmButtonText: '确定返回登录',
                        cancelButtonText: '取消',
                        type: 'warning',
        
                    }).then(() => {
                        router.push({
                            path: '/login'
                        })
                    })
                    localRemove('token')
        }  else if (res.Code != "0") {
            console.log('res.Code !="0"')
            if (res.Message) ElMessage.error(res.Message)
            return Promise.reject(res.Message)

        } else {
            return res
        }
    },
    err => {
        // ElMessage.error(error)
        // return Promise.reject(error)
        var config = err.config;
        // If config does not exist or the retry option is not set, reject
        if (!config || !config.retry) return Promise.reject(err);

        // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0;

        // Check if we've maxed out the total number of retries
        if (config.__retryCount >= config.retry) {
            // Reject with the error
            return Promise.reject(err)
        }

        // Increase the retry count
        config.__retryCount += 1;

        // Create new promise to handle exponential backoff
        var backoff = new Promise(function (resolve) {
            // ElMessage.error('请求超时')
            setTimeout(function () {
                resolve();
            }, config.retryDelay || 1);
        });
        // Return the promise in which recalls axios to retry the request
        return backoff.then(function () {
            return axios(config);
        })
    }
)

export default service