export function localGet(key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return value
  }
}

export function localSet(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}
export function localRemove(key) {
  window.localStorage.removeItem(key)
}
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
export function formatTime(d, x) {
  // let symbol=x?x:'/'
  let date = new Date(d)
  let symbol = x ? x : '-'
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  // console.log(hour, minute, second)
  return [year, month, day].map(formatNumber).join(symbol) + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

export function AuthJudgment(arr, str) {
  return arr.includes(str)

}

export function formatImgent(html,width=450) {
  var newContent = html.replace(/<img[^>]*>/gi, function (match) {
    console.log(match)
    let rel = /width\s*?=\s*?(['"])[\s\S]*?\1/g;
    let rel1 = /style\s*?=\s*?(['"])[\s\S]*?\1/g;
    if (match.indexOf('width') > 0) {
      match = match.replace(rel, `width=${width}px;`);
      console.log(match)
      return match;
    }
    if (match.indexOf('style') > 0) {
      console.log(2222)
      // match = match.replace(/max-width/gi, '').replace(/width:/gi, '').replace(/height=/gi, '').replace(/width=/gi, '').replace(/\s<img/gi, '<img style="width:90vw;height:auto;display:block;"')
      match = match.replace(rel1, `style="width:${width}px;height:auto;display:block;"`);
      console.log(match)
      return match;

    } else {
      match = match.replace(/<img/gi, `<img style="width:${width}px;height:auto;display:block;"`)
      return match;
    }



  });
  console.log(newContent)
  return newContent;

}