let add = [
    {
        path: '/feedBackType',
        name: 'feedBackType',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/feedBackType.vue')
      },
      {
        path: '/feedBackList',
        name: 'feedBackList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/feedBackList.vue')
      },
      {
        path: '/ActivityAudit',
        name: 'ActivityAudit',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/ActivityAudit.vue')
      },
]


export default  add