<template>
  <div class="layout">
    <!-- <el-scrollbar height="100vh" @scroll="handleScroll"> -->
    <div class="div" v-if="Fixed"></div>
    <el-container v-if="showMenu">
      <el-header :class="{ header: true, headFiexd: Fixed }">
        <div class="head_left">
          <img src="@/assets/logo.png" alt="" class="logo" />
          <div>商协宝管理系统</div>
          <div class="breadcrumb" v-if="path == '/addMember'">
            <div>会员列表 /&nbsp;</div>
            <div>{{ isEdit ? "编辑" : "新增" }}会员</div>
          </div>
          <div class="breadcrumb" v-if="path == '/addBanner'">
            <div>轮播图设置 /&nbsp;</div>
            <div>{{ isEdit ? "编辑" : "新增" }}轮播图</div>
          </div>
          <div class="breadcrumb" v-if="path == '/addIcon'">
            <div>图标设置 /&nbsp;</div>
            <div>{{ isEdit ? "编辑" : "新增" }}图标</div>
          </div>
          <div class="breadcrumb" v-if="path == '/addActivity'">
            <div>报名活动 /&nbsp;</div>
            <div>{{ isEdit ? "编辑" : "发布" }}活动</div>
          </div>

          <div class="breadcrumb" v-if="path == '/addQuestionnaire'">
            <div>问卷调查 /&nbsp;</div>
            <div>{{ isEdit ? "编辑" : "创建" }}问卷</div>
          </div>
        </div>

        <div>
          <el-dropdown>
            <div class="head_right">
              <img :src="selfInfo.FullLogo" alt="" class="tx" v-if="selfInfo.FullLogo" />
              <div>{{ selfInfo.Name }}</div>

              <img src="@/assets/icon/dropdown.png" alt="" class="down" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toJopCenter">个人中心</el-dropdown-item>
                <el-dropdown-item @click="handleOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside width="210px" :class="{ fiexd: Fixed }">
          <el-scrollbar height="calc(100vh - 60px);" style="background: #fff">
            <el-menu
              :default-active="currentPath"
              class="menu"
              @open="handleOpen"
              @close="handleClose"
              unique-opened
              :router="true"
              :default-openeds="defaultOpen"
            >
              <el-submenu :index="index" v-for="(item, index) in menuList" :key="index">
                <template #title>
                  <img :src="item.Icon" alt="" class="icon-left" />
                  <span>{{ item.DisplayName }}</span>
                </template>
                <el-menu-item-group>
                  <el-badge
                    :value="jtem.Badge"
                    class="item"
                    v-for="(jtem, jndex) in item.Children"
                    :key="jndex"
                  >
                    <el-menu-item :index="jtem.UrlPath">{{
                      jtem.DisplayName
                    }}</el-menu-item>
                  </el-badge>
                </el-menu-item-group>
              </el-submenu>
              <!-- <el-submenu index="3">
                <template #title>
                  <img
                    src="@/assets/icon/ziliao.png"
                    alt=""
                    class="icon-left"
                  />
                  <span>商协会资料管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/notice">入会设置</el-menu-item>
                  <el-menu-item index="/title">头衔设置</el-menu-item>
                  <el-menu-item index="/jobManagement"
                    >商协会职务管理</el-menu-item
                  >
                  <el-menu-item index="/sessionsNum">届数设置</el-menu-item>
                  <el-menu-item index="/fee">会费缴纳</el-menu-item>
                  <el-menu-item index="/integral">积分签到设置</el-menu-item>
                  <el-menu-item index="/invitation">邀请积分设置</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="1">
                <template #title>
                  <img
                    src="@/assets/icon/member.png"
                    alt=""
                    class="icon-left"
                  />
                  <span>会员管理</span>
                </template>

                <el-menu-item-group>
                  <el-menu-item index="/leaderShip">领导风采设置</el-menu-item>
                  <el-menu-item index="/member">会员列表</el-menu-item>
                  <el-badge :value="examineNum" class="item"
                    ><el-menu-item index="/examine"
                      >会员审核</el-menu-item
                    ></el-badge
                  >

                  <el-menu-item index="/membershipFee">会费管理</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="8">
                <template #title>
                  <img src="@/assets/icon/fw.png" alt="" class="icon-left" />
                  <span>服务管理</span>
                </template>

                <el-menu-item-group>
                  <el-menu-item index="/service">服务项目</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="5">
                <template #title>
                  <img src="@/assets/icon/hd.png" alt="" class="icon-left" />
                  <span>活动管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/Registration">报名活动</el-menu-item>
                  <el-menu-item index="/questionnaire">问卷调查</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="7">
                <template #title>
                  <img src="@/assets/icon/sj.png" alt="" class="icon-left" />
                  <span>需求管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/PriceSetting"
                    >置顶价格设置</el-menu-item
                  >
                  <el-menu-item index="/SupplyAudit">供给审核</el-menu-item>
                  <el-menu-item index="/OpportunityAudit"
                    >需求审核</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="2">
                <template #title>
                  <img src="@/assets/icon/file.png" alt="" class="icon-left" />
                  <span>内容管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/Information">资讯管理</el-menu-item>
                  <el-menu-item index="/icon">首页图标设置</el-menu-item>
                  <el-menu-item index="/banner">首页轮播图设置</el-menu-item>
                  <el-menu-item index="/tabberSetting"
                    >首页底部导航设置</el-menu-item
                  >
                  <el-menu-item index="/system">制度管理</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="6" v-if="true">
                <template #title>
                  <img src="@/assets/icon/xx.png" alt="" class="icon-left" />
                  <span>商协会评级</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/grade">5A评级</el-menu-item>
                  <el-menu-item index="/IndexSession"
                    >指标届数设置</el-menu-item
                  >
                  <el-menu-item index="/Scoringcriteria"
                    >等级评分标准</el-menu-item
                  >
                  <el-menu-item
                    index="/maintainData"
                    v-if="PhoneNumber == '18877712374'"
                    >指标数据维护</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="9">
                <template #title>
                  <img src="@/assets/icon/qx.png" alt="" class="icon-left" />
                  <span>权限管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/Department">部门管理</el-menu-item>
                  <el-menu-item index="/role">角色管理</el-menu-item>
                  <el-menu-item index="/addRoleMember">新增成员</el-menu-item>
                  <el-menu-item index="/memberPermissions"
                    >设置成员权限</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="4">
                <template #title>
                  <img src="@/assets/icon/sz.png" alt="" class="icon-left" />
                  <span>小程序设置</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/AppletInfo">小程序信息</el-menu-item>
                </el-menu-item-group>
              </el-submenu> -->
            </el-menu>
          </el-scrollbar>
        </el-aside>

        <!-- <div  class="transition-box">.el-fade-in-linear</div> SupplyAudit OpportunityAudit-->
        <el-container>
          <el-main :class="{ main: true, fiexdMain: Fixed }">
            <div style="width: 209px; flex-shrink: 0"></div>

            <router-view v-slot="{ Component }">
              <!-- <transition>
                <keep-alive>
                  <component :is="Component" v-if="$route.meta.keepAlive" />
                </keep-alive>
              </transition> -->
              <!-- v-if="!$route.meta.keepAlive" -->
              <component :is="Component"> </component>
            </router-view>
          </el-main>
          <el-footer style="height: 0px"></el-footer>
        </el-container>
        <!-- <el-main class="main">
          <router-view />
        </el-main> -->
      </el-container>
    </el-container>
    <el-container v-else class="container">
      <router-view />
    </el-container>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import { reactive, onUnmounted, ref, onMounted, toRefs, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { localGet, localRemove } from "@/utils";
import { logout } from "@/api/login";
import * as echarts from "echarts";
import { provide } from "vue";
import { getSelfMember } from "@/api/login";
import {
  ElMessageBox,
  // ElMessage
} from "element-plus";
import { GetGrantedMenus, GetOwnedPermissions } from "@/api/menu";
export default {
  setup() {
    const noMenu = ["/login"];
    const router = useRouter();
    // const Name = ref("admin");
    // const FullLogo = ref("");
    const Fixed = ref(false);
    // const PhoneNumber = ref("");
    const path = ref("");
    const store = useStore();
    console.log(store);
    const route = useRoute();

    const state = reactive({
      examineNum: store.state.appStore.examineNum,
      defaultOpen: ["1"],
      showMenu: true,
      currentPath: "/examine",
      isEdit: false,
      menuList: [],
    });

    const menuList = computed(() => {
      return store.state.appStore.menuList;
    });
    provide("ec", echarts);
    // 监听浏览器原生回退事件

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener(
        "popstate",
        () => {
          if (!localGet("token")) {
            state.showMenu = false;
          }
        },
        false
      );
    }
    //获取菜单列表
    const getList = () => {
      GetGrantedMenus().then((res) => {
        state.menuList = res.Data.map((item) => {
          item.Icon = item.Icon.replace("/assets/", "");
          return {
            ...item,
            Icon: require("@/assets/" + item.Icon),
          };
        });
        store.dispatch("updateMenuActions", state.menuList);
        console.log();
      });
    };
    //获取所有权限
    const getOwnedPermissions = () => {
      GetOwnedPermissions().then((res) => {
        console.log(res);
        store.dispatch("updatePermissionActions", res.Data);
        // console.log(store.state.appStore.OwnedPermissionsList);
      });
    };
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);

      if (localGet("token")) {
        getList();
        getOwnedPermissions();
        getSelfMember()
          .then((res) => {
            if (res.Data) {
              store.dispatch("updateSelfInfoActions", res.Data);
              // localSet("selfInfo", {
              //   Name: res.Data.Name,
              //   FullLogo: res.Data.FullLogo,
              //   PhoneNumber: res.Data.PhoneNumber,
              // });
              // router.push({ path: "/" });
            }
          })
          .catch(() => {
            // router.push({ path: "/" });
          });
      }
    });

    const handleScroll = () => {
      // console.log(scrollLeft);
      // console.log("1111");
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop = document.querySelector(".el-aside").offsetTop + 50;
      // console.log(scrollTop, offsetTop);
      if (scrollTop > offsetTop) {
        // console.log(true);
        Fixed.value = true;
      } else {
        // console.log(false);

        Fixed.value = false;
      }
    };

    // store.state.appStore.menuList;

    const selfInfo = computed(() => {
      console.log(store.state.appStore.selfInfo);
      return store.state.appStore.selfInfo;
    });
    // const FullLogo = computed(() => {
    //   return localGet("selfInfo").FullLogo;
    // });
    // const Name = computed(() => {
    //   return localGet("selfInfo").Name;
    // });
    // const PhoneNumber = computed(() => {
    //   return localGet("selfInfo").PhoneNumber;
    // });
    const unwatch = router.beforeEach((to, from, next) => {
      console.log(route.meta);
      if (to.path == "/login") {
        next();
      } else {
        if (!localGet("token")) {
          next({
            path: "/login",
          });
        } else {
          console.log(to);
          path.value = to.path;
          if (to.query.id) {
            state.isEdit = true;
          }
          // if (localGet("selfInfo")) {
          //   FullLogo.value = localGet("selfInfo").FullLogo;
          //   Name.value = localGet("selfInfo").Name;
          //   PhoneNumber.value = localGet("selfInfo").PhoneNumber;
          // }
          next();
        }
      }
      state.showMenu = !noMenu.includes(to.path);
      state.currentPath = to.path;
    });

    const toJopCenter = () => {
      router.push({ path: "/personalCenter" });
    };
    //退出登录
    const handleOut = () => {
      ElMessageBox.confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout().then(() => {
            router.push({ path: "/login" });
            localRemove("token");
            localRemove("selfInfo");
            localRemove("AppId");
          });
        })
        .catch(() => {
          // ElMessage({
          //     type: 'info',
          //     message: '已取消删除'
          // })
        });
    };
    const handleClickItem = (val) => {
      console.log(val);
    };

    onUnmounted(() => {
      unwatch();
    });

    return {
      ...toRefs(state),
      // Name,
      path,
      route,
      router,
      Fixed,
      // FullLogo,,selfInfo
      selfInfo,
      menuList,
      toJopCenter,
      handleOut,
      handleScroll,
      handleClickItem,
    };
  },
};
</script>

<style lang="scss">
.el-header {
  padding: 0 25px;
  background: #fff;
}

.el-menu-item.is-active {
  background: linear-gradient(
    90deg,
    rgba(38, 140, 255, 0.2) 0%,
    rgba(38, 140, 255, 0) 100%
  );
  color: #4a90e2;
  position: relative;
}

.el-menu-item-group__title {
  padding: 0;
}

.el-submenu .el-menu-item {
  height: 60px;
  line-height: 60px;
}
.el-badge__content.is-fixed {
  top: 30px;
  right: 100px;
  color: #fff;
  background: #ff3300;
}
.el-menu-item.is-active::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 60px;
  background: #268cff;
  left: 0;
  top: 0;
  border-radius: 0px 2px 2px 0px;
}
.el-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-dialog {
  margin-top: 0 !important;

  .el-dialog__header {
    padding: 16px 20px;
    border: 1px solid #edeef2;
    box-sizing: border-box;
  }

  .el-dialog__footer {
    padding: 15px 30px;
    border-top: 1px solid #edeef2;
    .el-button {
      width: 65px;
      height: 32px;
      border-radius: 2px;
      padding: 10px;
      min-height: 32px;
      line-height: 0;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  // box-shadow: 0px 1px 0px 0px #EDEEF2;
  border-bottom: 1px solid #edeef2;
align-items: center;
  .head_right {
    display: flex;
    align-items: center;
    height: 60px;

    .tx {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 6px;
    }

    .down {
      width: 10px;
      height: 6px;
      margin-left: 10px;
    }
  }

  .head_left {
    display: flex;
    font-size: 20px;
    color: #268cff;
    align-items: center;

    .logo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}

.main,
.menu {
  min-height: 100%;
  // overflow: auto;
}

.icon-left {
  width: 20px;
  height: 20px;
  padding-right: 5px;
}

.main {
  background: #f5f6fa;
  padding: 50px;

  .content {
    background: #fff;
    min-height: calc(100vh - 160px);
    // overflow: auto;
    flex: 1;
  }
}

.el-menu {
  box-shadow: 1px 0px 0px 0px #edeef2;
}
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .el-pager li {
  margin: 0;
  background-color: #fff;
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  box-sizing: border-box;
  border: 1px solid #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #1890ff;
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  color: #1890ff;
  box-sizing: border-box;
}
.el-pagination .btn-next {
  margin-top: 2px !important;
}
.el-scrollbar {
  flex: 1;
}
.fiexd {
  position: fixed;
  top: 60px;
  height: calc(100vh - 60px);
}
.headFiexd {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.fiexdMain {
  // width: calc(100vw - 210px);
  display: flex;
  justify-content: space-between;
}
.div {
  height: 60px;
}
.breadcrumb {
  color: #666666;
  font-size: 14px;
  display: flex;
  margin-left: 50px;
  & > div:nth-child(2) {
    color: #268cff;
  }
}
.item {
  // margin-top: 10px;
  margin-right: 40px;
}
.el-dropdown {
  margin-top: 1.1rem;
}
</style>
<style>
/* ::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.3);
}
::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.1);
}
overflow-y: scroll;
scrollbar-color: transparent transparent;
scrollbar-track-color: transparent;
-ms-scrollbar-track-color: transparent; */

/* ::-webkit-scrollbar {
  display: none;
} */
</style>
