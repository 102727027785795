import {
    createApp
} from 'vue'
import ElementPlus from 'element-plus';
import VueCropper from 'vue-cropper'
import 'element-plus/lib/theme-chalk/index.css'
// import 'element-plus/lib/theme-chalk/base.css';
// import {
//     ElCollapseTransition
//     } from 'element-plus';
import './assets/style/element-variables.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import locale from "element-plus/lib/locale/lang/zh-cn"

import * as echarts from 'echarts'
// import VueDND from 'awe-dnd'
import 'dayjs/locale/zh-cn'

//查看大图插件
// import preview from 'vue-photo-preview'
// import 'vue-photo-preview/dist/skin.css'
// import locale from 'element-plus/lib/locale' .use(preview)
//reset.css
import '../src/assets/style/reset.css'

createApp(App).use(VueCropper).use(store).use(router).use(ElementPlus, {
    locale
}).mount('#app').echarts = echarts
// App.component(ElCollapseTransition.name, ElCollapseTransition)